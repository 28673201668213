import store from '@/store'
import axios from 'axios'

// import { getToken } from '@/utils/auth'
//var baseURL = themeConfig.app.fileLink
// create an axios instance
const service = axios.create({
  baseURL: window.location.protocol + '//' + window.location.host + ':8090/',
  // baseURL: 'http://localhost:11224',
  //baseURL: themeConfig.app.fileLink,
  timeout: 90000, // request timeout
  withCredentials: true,
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent    "Content-Type": "application/json"
    // console.log("config" , config);
    //config.headers['X-Requested-With']= 'XMLHttpRequest',
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    //config.headers['Accept'] = 'application/x-www-form-urlencoded'

    //config.headers['Content-Type'] = 'application/javascript'
    //config.headers['Content-Type'] = 'text/html'
    //config.headers['Content-Type'] = 'text/plain'
    // config.headers['Access-Control-Allow-Origin'] = '*'
    //config.headers['Access-Control-Allow-Headers'] = 'Set-Cookie'
    //Access-Control-Allow-Origin
    // config.headers['Cache-Control'] ='no-cache'
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      //  config.headers['Authorization'] = getToken()
    }

    return config
  },
  error => {
    // do something with request error
    console.error('do something with request error', 'Error')

    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    console.log('response', res)

    if (res.status !== 200) {
      console.error('! == 200', res.message || 'Error')

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.status === 508 || res.status === 512 || res.status === 514) {
        // to re-login
        // 'You have been logged out, you can cancel to stay on this page, or log in again'
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')
        // location.reload()
      }

      return Promise.reject(new Error(res.message || 'Error'))
    }

    return res.data

    // if the custom code is not 20000, it is judged as an error.
  },
  error => {
    console.error('server is not Runing', 'Error')
    localStorage.removeItem('accessToken')

    // Remove userData & Ability from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')
    //  location.reload()

    return Promise.reject(error)
  },
)

export default service
