import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userRole === 'DEVELOPER') return { name: 'AdminHome' }
      if (userRole === 'ADMIN') return { name: 'AdminHome' }
      if (userRole === 'CASHIER' ) return { name: 'CashierHome' }
      if (userRole === 'AGENT' ) return { name: 'AgentHome' }
      if (userRole === 'OWNER' ) return { name: 'OwnerHome' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/Admin-Home',
    name: 'AdminHome',
    component: () => import('@/views/Home/Admin/Index.vue'),
    meta: {
      resource: 'AdminHome',
      layout: 'content',
    },
  },
  {
    path: '/Cashier-home',
    name: 'CashierHome',
    component: () => import('@/views/Home/Casher/Index.vue'),
    meta: {
      resource: 'CashierHome',
      layout: 'content',
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      resource: 'user',
      layout: 'content',
    },
  },
  {
    path: '/Role',
    name: 'Role',
    component: () => import('@/views/Role/List.vue'),
    meta: {
      resource: 'Role',
      layout: 'content',
    },
  },
  {
    path: '/Address',
    name: 'Address',
    component: () => import('@/views/Menu/Address/List.vue'),
    meta: {
      resource: 'Address',
      layout: 'content',
    },
  },
  {
    path: '/:name/Branch/:id/',
    name: 'Branch',
    component: () => import('@/views/Branch/List.vue'),
    meta: {
      resource: 'Branch',
      layout: 'content',
    },
  },
  {
    path: '/Shop/:name/Branch/:id/',
    name: 'ShopBranch',
    component: () => import('@/views/Shop/Branch/List.vue'),
    meta: {
      resource: 'ShopBranch',
      layout: 'content',
    },
  },
  {
    path: '/AddressBranch/:id',
    name: 'AddressBranch',
    component: () => import('@/views/Menu/Address/AddressBranchList.vue'),
    meta: {
      resource: 'AddressBranch',
      layout: 'content',
    },
  },
  {
    path: '/MenuList',
    name: 'MenuList',
    component: () => import('@/views/Menu/Restaurant/List.vue'),
    meta: {
      resource: 'MenuList',
      layout: 'content',
    },
  },

  {
    path: '/CategoryList/:id',
    name: 'CategoryList',
    component: () => import('@/views/Menu/Category/CategoryList.vue'),
    meta: {
      resource: 'CategoryList',
      layout: 'content',
    },
  },
  {
    path: '/AddressList/:id',
    name: 'AddressList',
    component: () => import('@/views/Menu/Address/AddressList.vue'),
    meta: {
      resource: 'AddressList',
      layout: 'content',
    },
  },
  {
    path: '/AddonList/:id',
    name: 'AddonList',
    component: () => import('@/views/Menu/Addons/AddonList.vue'),
    meta: {
      resource: 'AddonList',
      layout: 'content',
    },
  },
  {
    path: '/OptionList/:id',
    name: 'OptionList',
    component: () => import('@/views/Menu/Options/OptionList.vue'),
    meta: {
      resource: 'OptionList',
      layout: 'content',
    },
  },
  {
    path: '/ItemList/:id',
    name: 'ItemList',
    component: () => import('@/views/Menu/Items/ItemList.vue'),
    meta: {
      resource: 'ItemList',
      layout: 'content',
    },
  },
  {
    path: '/AddAddress/:id',
    name: 'AddAddress',
    component: () => import('@/views/Menu/Address/AddAddress.vue'),
    meta: {
      resource: 'AddAddress',
      layout: 'content',
    },
  },
  {
    path: '/AgentHome',
    name: 'AgentHome',
    component: () => import('@/views/AgentSection/AgentHome/Home.vue'),
    meta: {
      resource: 'AgentHome',
      layout: 'content',
    },
  },
  {
    path: '/:name/AgentBranch/:id',
    name: 'AgentBranch',
    component: () => import('@/views/AgentSection/AgentBranch/List.vue'),
    meta: {
      resource: 'AgentBranch',
      layout: 'content',
    },
  },
// /Restaurant/1234567899/Customer/0796161714
  // {
  //   path: '/Restaurant/:restaurantnumber/Customer/:customernumber',
  //   name: 'api',
  //   component: () => import('@/views/AgentSection/AgentBranchAuto/List.vue'),
  //   meta: {
  //     resource: 'api',
  //     layout: 'content',
  //   },
  // },
  {
    path: '/AgentBranchAuto/:resname/:num/:id',
    name: 'api',
    component: () => import('@/views/AgentSection/AgentBranchAuto/List.vue'),
    meta: {
      resource: 'api',
      layout: 'content',
    },
  },
  {
    path: '/Restaurant/:restaurantnumber/Customer/:customernumber',
    name: 'skel',
    component: () => import('@/views/AgentSection/AgentBranchAuto/skel.vue'),
    meta: {
      resource: 'skel',
      layout: 'content',
    },
  },

  {
    path: '/CustomerList',
    name: 'CustomerList',
    component: () => import('@/views/Customer/CustomerList.vue'),
    meta: {
      resource: 'CustomerList',
      layout: 'content',
    },
  },
  {
    path: '/PromoCodeList',
    name: 'PromoCodeList',
    component: () => import('@/views/PromoCode/List.vue'),
    meta: {
      resource: 'PromoCodeList',
      layout: 'content',
    },
  },
  {
    path: '/Shop/Restaurant/List',
    name: 'Shop',
    component: () => import('@/views/Shop/List.vue'),
    meta: {
      resource: 'Shop',
      layout: 'content',
    },
  },
  {
    path: '/Cashier/Item/List',
    name: 'CashierItemList',
    component: () => import('@/views/Home/Casher/ItemList.vue'),
    meta: {
      resource: 'CashierItemList',
      layout: 'content',
    },
  },
  {
    path: '/Cashier/Order/List',
    name: 'CashierOrderList',
    component: () => import('@/views/Home/Casher/OrderList.vue'),
    meta: {
      resource: 'CashierOrderList',
      layout: 'content',
    },
  },
  {
    path: '/Cashier/Order/Schedule',
    name: 'OrderSchedule',
    component: () => import('@/views/Home/Casher/OrderSchedule.vue'),
    meta: {
      resource: 'OrderSchedule',
      layout: 'content',
    },
  },
  {
    path: '/Restaurant/Notes',
    name: 'RestaurantNote',
    component: () => import('@/views/RestaurantNotes/List.vue'),
    meta: {
      resource: 'RestaurantNote',
      layout: 'content',
    },
  },
  {
    path: '/Agent/Complaint/List',
    name: 'AgentComplaint',
    component: () => import('@/views/AgentSection/AgentHome/BranchOrder.vue'),
    meta: {
      resource: 'AgentComplaint',
      layout: 'content',
    },
  },
   {
     path: '/WebPush',
     name: 'WebPush',
     component: () => import('@/views/WebPush/List.vue'),
     meta: {
       resource: 'WebPush',
       layout: 'content',
     },
   },
   {
    path: '/ServerKeys',
    name: 'ServerKeys',
    component: () => import('@/views/WebPush/Server.vue'),
    meta: {
      resource: 'ServerKeys',
      layout: 'content',
    },
  },
  // {
  //   path: '/sw.js',
  //   name: 'sw.js',
  //   component: () => import('@/views/WebPush/sw.js'),
  //   meta: {
  //     resource: 'sw.js',
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/service-worker.js',
  //   name: 'service-worker.js',
  //   component: () => import('public/service-worker.js'),
  //   meta: {
  //     resource: 'service-worker.js',
  //     layout: 'Public',
  //   },
  // },
   {
    path: '/Chat',
    name: 'Chat',
    component: () => import('@/views/WebPush/Chat.vue'),
    meta: {
      resource: 'Chat',
      layout: 'content',
    },
  },
  {
    path: '/AgentOrder',
    name: 'AgentOrder',
    component: () => import('@/views/AgentSection/AgentOrders/BranchOrder.vue'),
    meta: {
      resource: 'AgentOrder',
      layout: 'content',
    },
  },
  {
    path: '/RestaurantCustomers',
    name: 'RestaurantCustomers',
    component: () => import('@/views/Home/Admin/Components/RestaurantCustomers.vue'),
    meta: {
      resource: 'RestaurantCustomers',
      layout: 'content',
    },
  },
  {
    path: '/Owner/Home',
    name: 'OwnerHome',
    component: () => import('@/views/Home/Owner/BranchOrder.vue'),
    meta: {
      resource: 'OwnerHome',
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')

  // console.log('userAbility', localStorage.getItem('userAbility'))
  // console.log('userData', localStorage.getItem('userData'))
  const isLoggedIn = userData && localStorage.getItem('userAbility') // && localStorage.getItem('accessToken')
  // console.log('!canNavigate(to)', !canNavigate(to))

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
