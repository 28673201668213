const state = {
  users: [
    { title: 'Pending', value: 'pending', variant: 'warning' },
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
    { title: 'Locked Out', value: 'lockedout', variant: 'secondary' },
  ],
  restaurants: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  branches: [
    { title: 'Online', value: 'online', variant: 'success' },
    { title: 'Offline', value: 'offline', variant: 'error' },
  ],
  addresses: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  addons: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  categories: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  options: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  items: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  customers: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  promocodes: [
    { title: 'Active', value: 'active', variant: 'success' },
    { title: 'Inactive', value: 'inactive', variant: 'error' },
  ],
  orders: [
    { title: 'Cancel', value: 'cancelled', variant: 'error' },
    { title: 'Pinding', value: 'pinding', variant: 'warning' },
    { title: 'Accept', value: 'accept', variant: 'success' },
    { title: 'Finish', value: 'finished', variant: 'primary' },
    { title: 'CancelRequest', value: 'cancelrequest', variant: 'error' },
  ],
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
