<template>
  <div>
    <fullscreen v-model="isFullscreen" :teleport="teleport" :page-only="pageOnly" />
    <v-icon @click="click">
      {{ isFullscreen ? icons.mdiFullscreenExit : icons.mdiFullscreen }}
    </v-icon>
  </div>
</template>

<script>
import { mdiFullscreen, mdiFullscreenExit } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { component } from 'vue-fullscreen'
export default {
  components: { fullscreen: component },
  setup() {
    const isFullscreen = ref(false)
    const teleport = ref(true)
    const pageOnly = ref(false)
    const click = () => {
      isFullscreen.value = !isFullscreen.value
    }
    return {
      isFullscreen,
      teleport,
      pageOnly,
      click,
      icons: {
        mdiFullscreen,
        mdiFullscreenExit,
      },
    }
  },
}
</script>
