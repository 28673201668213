// eslint-disable-next-line object-curly-newline
import {
  mdiAccount, mdiAccountAlert, mdiAccountCog, mdiAccountGroup, mdiCalendarMonth, mdiCardAccountDetails, mdiChat, mdiClipboardList, mdiFood, mdiHome, mdiHomeAccount, mdiHomeCircle, mdiHomeGroup, mdiLabelPercent, mdiMapMarker, mdiMessageSettings, mdiNoteMultiple, mdiShieldKey, mdiSilverwareForkKnife, mdiStore, mdiViewList
} from '@mdi/js'

export default [
  {
    subheader: 'Agent',
  },
  {
    title: 'Agent Home',
    icon: mdiHomeAccount,
    to: 'AgentHome',
    resource: 'AgentHome',
    action: 'read',
  },
  {
    title: 'Complains',
    icon: mdiAccountAlert,
    to: 'AgentComplaint',
    resource: 'AgentComplaint',
    action: 'read',
  },
  {
    title: 'Agent Notes',
    icon: mdiNoteMultiple,
    to: 'RestaurantNote',
    resource: 'RestaurantNote',
    action: 'read',
  },
  // {
  //   title: 'Agent Orders',
  //   icon: mdiViewList,
  //   to: 'AgentOrder',
  //   resource: 'AgentOrder',
  //   action: 'read',
  // },
  {
    subheader: 'Cashier',
  },
  {
    title: 'Cashier Home',
    icon: mdiHomeCircle,
    to: 'CashierHome',
    resource: 'CashierHome',
    action: 'read',
  },
  {
    title: 'Item List',
    icon: mdiViewList,
    to: 'CashierItemList',
    resource: 'CashierItemList',
    action: 'read',
  },
  {
    title: 'Order List',
    icon: mdiClipboardList,
    to: 'CashierOrderList',
    resource: 'CashierOrderList',
    action: 'read',
  },
  {
    title: 'Scheduled Order',
    icon: mdiCalendarMonth,
    to: 'OrderSchedule',
    resource: 'OrderSchedule',
    action: 'read',
  },
  {
    subheader: 'Admin',
  },
  {
    title: 'Home',
    icon: mdiHome,
    to: 'AdminHome',
    resource: 'AdminHome',
    action: 'read',
  },
  {
    title: 'Menu',
    icon: mdiFood,
    to: 'MenuList',
    resource: 'MenuList',
    action: 'read',
  },
  {
    title: 'Address',
    icon: mdiMapMarker,
    to: 'Address',
    resource: 'Address',
    action: 'read',
  },
  {
    title: 'Customers',
    icon: mdiAccountGroup,
    to: 'CustomerList',
    resource: 'CustomerList',
    action: 'read',
  },
  {
    title: 'Restaurant Clients',
    icon: mdiCardAccountDetails,
    to: 'RestaurantCustomers',
    resource: 'RestaurantCustomers',
    action: 'read',
  },
  {
    subheader: 'Owner',
  },
  {
    title: 'Owner Home',
    icon: mdiHomeGroup,
    to: 'OwnerHome',
    resource: 'OwnerHome',
    action: 'read',
  },
  {
    subheader: 'Control',
  },
  {
    title: 'Users',
    icon: mdiAccount,
    to: 'user',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'Roles',
    icon: mdiAccountCog,
    to: 'Role',
    resource: 'Role',
    action: 'read',
  },
  {
    title: 'PromoCodes',
    icon: mdiLabelPercent,
    to: 'PromoCodeList',
    resource: 'PromoCodeList',
    action: 'read',
  },
  {
    title: 'WebPush',
    icon: mdiMessageSettings,
    to: 'WebPush',
    resource: 'WebPush',
    action: 'read',
  },
  {
    title: 'ServerKeys',
    icon: mdiShieldKey,
    to: 'ServerKeys',
    resource: 'ServerKeys',
    action: 'read',
  },
  {
    title: 'Chat',
    icon: mdiChat,
    to: 'Chat',
    resource: 'Chat',
    action: 'read',
  },
  {
    title: 'Shops',
    icon: mdiStore,
    children: [
      {
        title: 'Restaurant',
        to: 'Shop',
        icon: mdiSilverwareForkKnife,
        resource: 'Shop',
        action: 'read',
      },
    ],
  },
]
