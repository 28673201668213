<template>

  <!-- lazy-src="@/assets/images/misc/loading.png" -->
  <div>
   <div v-if="avatar">

    <div v-if="width == null">
      <v-img :src="avatar" :height="height"></v-img>
    </div>

    <div v-else>
     <v-img :src="avatar" :width="width" :height="height"></v-img>
    </div>
  </div>

   <div v-else>
  <span class="font-weight-medium">{{ avatarText(LabelText) }}</span>
  </div>
</div>
</template>
<script>
import { GetFileByObjId } from '@core/api/File'
import { avatarText } from '@core/utils/filter'
//import themeConfig from '@themeConfig'
import { ref, watch } from '@vue/composition-api'
export default {
  props: {
    ObjectId: {
      type: Number,
      default: () => null,
    },
    TableName: {
      type: String,
      default: () => null,
    },
    LabelText: {
      type: String,
      default: () => 'Nashmi',
    },
    height: {
      type: Number,
      default: () => 100,
    },
    width: {
      type: Number,
      default: () => null,
    },
  },

  setup(props) {
    const selected = ref(props.ObjectId)
    //const obj = selected.value.toString()
    watch(selected, (first, second) => {
      console.log('Watch props.selected function called with args:', first, second)
    })

    const avatar = ref('')
   // var host = themeConfig.app.fileLink
  //  let link = `${host}/app-images/${props.TableName}/${obj}.jpeg`
  //  var request = new XMLHttpRequest();
  //    request.open('GET', link, true);
  //     request.onreadystatechange = function(){
    //    if (request.readyState === 4){
    //      if (request.status === 404) {
    //       avatar.value = false
    //    }
   // }
  //};
     // request.send();
     const GetFile = async () => {
       await GetFileByObjId({ TableName: props.TableName, ObjId: props.ObjectId }).then(res => {
         if (res) avatar.value = res.File
       })
     }

    return { GetFile,
      selected,
      //host,
      //link,
      avatar,
      avatarText }
  },
  beforeMount() {
    this.GetFile()
  },
}
</script>
