<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <span class="text--primary font-weight-semibold mb-n1">
          {{ userData.fullname }}
        </span>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <vc-image
            :width="40"
            :height="40"
            :ObjectId="parseInt(userData.phone)"
            TableName="User"
            :label-text="userData.fullname"
          />
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <vc-image
              :width="40"
              :height="40"
              :ObjectId="parseInt(userData.phone)"
              TableName="User"
              :label-text="userData.fullname"
            />
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.name }}
          </span>

          <small class="text--disabled text-capitalize">
            {{ userData.role }}
          </small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Settings -->
      <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import VcImage from '@/components/vc-avatar/VcImage.vue'
import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'
import { useRouter } from '@core/utils'
import {
mdiAccountOutline,
mdiChatOutline,
mdiCheckboxMarkedOutline,
mdiCogOutline,
mdiCurrencyUsd,
mdiEmailOutline,
mdiHelpCircleOutline,
mdiLogoutVariant
} from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  components: { VcImage },
  setup() {
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const vm = getCurrentInstance().proxy

    const logoutUser = () => {
      // logout From Api
      store.dispatch('user/logout').then(() => {
        // Reset ability
        vm.$ability.update(initialAbility)
        // Redirect to login page
        router.push({ name: 'auth-login' })
        location.reload()
      })
    }
    return {
      logoutUser,
      userData,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
